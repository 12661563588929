import type {
  GraphQLExtensions,
  InvalidCredentialError,
  AllowedFrameStyle,
  HighnoteEnvironment,
} from ".";

export const cardFields = ["cardNumber", "cvv", "expirationDate"] as const;

export type PaymentCardFields = (typeof cardFields)[number];

export interface CardViewerRenderedFields {
  // Unmount all of the embedded iframes.
  unmount: () => Promise<void>;

  //  Toggle card number masking.
  toggleCardNumberMask: () => void;
}

export interface CardViewerElementConfig {
  // A valid [DOMString](https://developer.mozilla.org/en-US/docs/Web/API/DOMString) that is a valid CSS Selector.
  //
  // Examples: "#foo", ".bar", '[data-foo="bar"]'
  //
  selector: string;

  styles?: AllowedFrameStyle;
}

export type OnCopyToClipboardSuccessPayload = Readonly<{
  field: PaymentCardFields;
}>;

export interface CardViewerConfig {
  /** A client token generated via the [`generatePaymentCardClientToken`](https://highnote.com/docs/reference/mutation/generatePaymentCardClientToken) mutation. */
  clientToken: string;

  paymentCardId: string;

  elements: Record<PaymentCardFields, CardViewerElementConfig>;

  onError: (error: CardViewerConfigError) => void;

  /**
   * Callback for when the user successfully copies a value to their clipboard.
   *
   * Errors from this action will be dispatched to the `onError` callback.
   */
  onCopyToClipboardSuccess?: (payload: OnCopyToClipboardSuccessPayload) => void;

  // eslint-disable-next-line multiline-comment-style
  /** The environment to use for making requests to the Highnote API.
   *
   * default value: 'test'
   */
  environment?: HighnoteEnvironment;
  /**
   * Configurable value to enable or disable the use copying value to clipboard functionality on click.
   * Default: true
   */
  enableClipboard?: boolean;
}

export enum GlobalPostMessageEventKind {
  PAYMENT_CARD_FETCH_ERROR = "PAYMENT_CARD_FETCH_ERROR",
  PAYMENT_CARD_FETCH_SUCCESS = "PAYMENT_CARD_FETCH_SUCCESS",
  // Input/configuration error kind
  CARD_VIEWER_INPUT_ERROR = "CARD_VIEWER_INPUT_ERROR",
  INVALID_CREDENTIAL_ERROR = "INVALID_CREDENTIAL_ERROR",
  GENERIC_ERROR = "GENERIC_ERROR",
  TOGGLE_CARD_NUMBER_MASK = "TOGGLE_CARD_NUMBER_MASK",
  COPY_TO_CLIPBOARD_ERROR = "COPY_TO_CLIPBOARD_ERROR",
  COPY_TO_CLIPBOARD_SUCCESS = "COPY_TO_CLIPBOARD_SUCCESS",
}

// Construct for postMessage events meant to be sent across origins.
export type GlobalPostMessageEvent =
  | {
      __typename: GlobalPostMessageEventKind.CARD_VIEWER_INPUT_ERROR;
      message: string;
    }
  | {
      __typename: GlobalPostMessageEventKind.COPY_TO_CLIPBOARD_ERROR;
      message: string;
      field: PaymentCardFields;
    }
  | {
      __typename: GlobalPostMessageEventKind.COPY_TO_CLIPBOARD_SUCCESS;
      field: PaymentCardFields;
    }
  | {
      __typename: GlobalPostMessageEventKind.GENERIC_ERROR;
      message: string;
    }
  | {
      __typename: GlobalPostMessageEventKind.INVALID_CREDENTIAL_ERROR;
      context: GraphQLExtensions;
    }
  | {
      __typename: GlobalPostMessageEventKind.PAYMENT_CARD_FETCH_ERROR;
      message: string;
      context: GraphQLExtensions;
    }
  | {
      __typename: GlobalPostMessageEventKind.PAYMENT_CARD_FETCH_SUCCESS;
    }
  | {
      __typename: GlobalPostMessageEventKind.TOGGLE_CARD_NUMBER_MASK;
    };

// This error is presented when the input configuration is invalid.
export type CardViewerFieldsInputError = Readonly<{
  name: "CardViewerFieldsInputError";
  message: string;
}>;

// A generic catch-all error. Typically, this captures exceptions that are not recoverable.
export type CardViewerError = Readonly<{ message: string }>;

export type CardViewerRequestError = Readonly<{
  name: "CardViewerRequestError";
  message?: string;
  context: GraphQLExtensions;
}>;

// An error that occurs when a value is unable to be masked/unmasked.
export type CardViewerToggleMaskError = Readonly<{
  name: "CardViewerToggleMaskError";
  message?: string;
}>;

// An error that occurs when the copy to clipboard action fails.
export type CardViewerCopyToClipboardError = Readonly<{
  name: "CopyToClipboardError";
  message: string;
  field: PaymentCardFields;
}>;

// All the errors that can be returned onError.
export type CardViewerConfigError = Readonly<
  | CardViewerCopyToClipboardError
  | CardViewerError
  | CardViewerFieldsInputError
  | CardViewerRequestError
  | InvalidCredentialError
>;
