import type {
  AllowedFrameStyle,
  CardViewerConfig,
  HighnoteEnvironment,
  PaymentCardFields,
} from "../../types";

import { iframeUri } from "./config";

type BuildFrameConfig =
  | {
      uuid: string;
      field: PaymentCardFields;
      styles?: AllowedFrameStyle;
      enableClipboard?: boolean;
    }
  | (Pick<CardViewerConfig, "clientToken" | "paymentCardId"> & {
      uuid: string;
      environment: HighnoteEnvironment;
      styles?: AllowedFrameStyle;
      enableClipboard?: boolean;
    });

export const buildFrame = (
  config: Readonly<BuildFrameConfig>
): HTMLIFrameElement => {
  const frame = document.createElement("iframe");

  const frameSource = new URL(
    "clientToken" in config
      ? `${iframeUri}?field=cardNumber&token=${
          config.clientToken
        }&environment=${config.environment}&paymentCardId=${
          config.paymentCardId
        }&styles=${encodeURIComponent(
          JSON.stringify(config.styles ?? {})
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        )}&enableClipboard=${config.enableClipboard ? "true" : "false"}`
      : `${iframeUri}?field=${config.field}&styles=${encodeURIComponent(
          JSON.stringify(config.styles ?? {})
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        )}&enableClipboard=${config.enableClipboard ? "true" : "false"}`
  );

  // eslint-disable-next-line fp/no-mutation
  frameSource.hash = config.uuid;

  frame.setAttribute("src", frameSource.toString());
  frame.setAttribute("allow", "clipboard-write");
  frame.style.setProperty("border", "none");

  return frame;
};
