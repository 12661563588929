/* eslint-disable max-statements */
import {
  type CardViewerConfig,
  type CardViewerCopyToClipboardError,
  type CardViewerFieldsInputError,
  type CardViewerRequestError,
  type GlobalPostMessageEvent,
  type HighnotePostMessageEvent,
  type InvalidCredentialError,
  GlobalPostMessageEventKind,
} from "../../types";

import { iframeOrigin } from "./config";

const FALLBACK_ERROR_MESSAGE =
  "Something went wrong. Please try again or contact support.";

export const handlePostMessage =
  (
    uuid: string,
    onError: CardViewerConfig["onError"],
    onCopyToClipboardSuccess: CardViewerConfig["onCopyToClipboardSuccess"]
  ) =>
  (
    event: MessageEvent<HighnotePostMessageEvent<GlobalPostMessageEvent>>
  ): boolean => {
    if (event.origin !== iframeOrigin || uuid !== event.data.uuid) {
      return false;
    }

    const { data } = event.data;

    switch (data.__typename) {
      case GlobalPostMessageEventKind.INVALID_CREDENTIAL_ERROR: {
        const error: InvalidCredentialError = {
          name: "InvalidCredentialError",
          context: data.context,
        };

        onError(error);
        break;
      }
      case GlobalPostMessageEventKind.CARD_VIEWER_INPUT_ERROR: {
        const error: CardViewerFieldsInputError = {
          name: "CardViewerFieldsInputError",
          message: data.message,
        };

        onError(error);
        break;
      }
      case GlobalPostMessageEventKind.PAYMENT_CARD_FETCH_ERROR: {
        const error: CardViewerRequestError = {
          name: "CardViewerRequestError",
          context: data.context,
          message: data.message,
        };

        onError(error);
        break;
      }
      case GlobalPostMessageEventKind.COPY_TO_CLIPBOARD_ERROR: {
        const error: CardViewerCopyToClipboardError = {
          name: "CopyToClipboardError",
          message: data.message,
          field: data.field,
        };

        onError(error);
        break;
      }
      case GlobalPostMessageEventKind.COPY_TO_CLIPBOARD_SUCCESS:
        onCopyToClipboardSuccess?.({
          field: data.field,
        });

        break;
      default:
        onError({
          message: FALLBACK_ERROR_MESSAGE,
        });
    }

    return true;
  };
