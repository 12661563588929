import {
  cardFields,
  GlobalPostMessageEventKind,
  type CardViewerConfig,
  type CardViewerRenderedFields,
  type CardViewerToggleMaskError,
  type Maybe,
  type PaymentCardFields,
} from "../../types";

import { buildFrame } from "./buildFrame";
import { iframeOrigin, generateUuid } from "./config";
import { handlePostMessage } from "./handlePostMessage";

type FieldsMap = Readonly<
  Record<
    PaymentCardFields,
    Maybe<{ container: Element; frame: HTMLIFrameElement }>
  >
>;

// eslint-disable-next-line @typescript-eslint/require-await, putout/putout
const unmount = async (fields: Readonly<FieldsMap>): Promise<void> => {
  Object.values(fields).forEach((field) => {
    if (field) {
      field.container.replaceChildren("");
    }
  });
};

export const renderFields = async ({
  clientToken,
  paymentCardId,
  elements,
  onError,
  onCopyToClipboardSuccess,
  environment = "test",
  enableClipboard = true,
}: // eslint-disable-next-line @typescript-eslint/require-await
Readonly<CardViewerConfig>): Promise<CardViewerRenderedFields> => {
  const uuid = generateUuid();
  const fields = cardFields.reduce<FieldsMap>(
    (accum, field) => {
      const container =
        document.querySelector(elements[field].selector) ?? document.body;

      const frame =
        field === "cardNumber"
          ? buildFrame({
              clientToken,
              paymentCardId,
              styles: elements[field].styles,
              environment,
              enableClipboard,
              uuid,
            })
          : buildFrame({
              field,
              styles: elements[field].styles,
              enableClipboard,
              uuid,
            });

      container.append(frame);

      return {
        ...accum,
        [field]: { container, frame },
      };
    },
    {
      cardNumber: undefined,
      cvv: undefined,
      expirationDate: undefined,
    }
  );

  window.removeEventListener(
    "message",
    handlePostMessage(uuid, onError, onCopyToClipboardSuccess)
  );
  window.addEventListener(
    "message",
    handlePostMessage(uuid, onError, onCopyToClipboardSuccess)
  );

  return {
    toggleCardNumberMask: (): Maybe<CardViewerToggleMaskError> => {
      const targetWindow = fields.cardNumber?.frame.contentWindow;

      if (targetWindow === null || targetWindow === undefined) {
        return {
          name: "CardViewerToggleMaskError",
          message: "Unable to toggle card number mask.",
        };
      }

      targetWindow.postMessage(
        { __typename: GlobalPostMessageEventKind.TOGGLE_CARD_NUMBER_MASK },
        iframeOrigin
      );

      return undefined;
    },

    unmount: async () => {
      await unmount(fields);
    },
  };
};
